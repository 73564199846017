import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";
import Recent from "../../components/News/Recent";

class IrishPage extends Component {
  render() {
    return (
      <Layout>
        <div className="irish-news-container">
          <Helmet title={`Irish Market and Business News | ${config.siteTitle}`} />
          <Recent />
        </div>
      </Layout>
    );
  }
}

export default IrishPage;
