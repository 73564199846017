import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import config from "../../../data/SiteConfig";
const _ = require("lodash");


const Recent = () => {
  const data = useStaticQuery(graphql`
query RecentQuery {
  recentcomps: allAirtable(sort: { fields: [data___publishedAt] order: DESC }, limit: 5, filter: {table: {eq: "IrishBusinessNews"}}) {
    nodes {
      data {
            title
            url
            content
            source
            description
      }
      recordId
    }
  }
}



  `);


  return (

    <div className="relative pt-16 pb-10 sm:px-6">

    <div className="relative mx-auto">
        <div className="text-left">
        <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Irish Business News
        </h2>
        <p className="mt-3 max-w-2xl text-xl leading-7 text-gray-500 sm:mt-4">
            The latest Irish business news
        </p>
        </div>
<div className="bg-white">
  <div className="mx-auto py-12 px-4 max-w-screen-xl">
    <div className="space-y-12">

      <ul className="space-y-12 lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
        {data.recentcomps.nodes.map((item, i) => (          
        <li>
          <a href={item.data.url} target="_blank">
          <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
            <div className="sm:col-span-2">
              <div className="space-y-4">
                <div className="text-lg leading-6 font-medium space-y-1">
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900 pt-2">
                    {item.data.title.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
                </h3>        
                    <span className="inline-flex items-center py-0.5 text-sm font-medium leading-5 text-teal-800 capitalize">
                        {item.data.source}
                    </span>

                  <p className="text-blue-600 pt-0">Read full story</p>              
                </div>
              </div>
            </div>
          </div>
          </a>  
        </li>
        ))}

      </ul>



        </div>
    </div>
    </div>
    </div>
    </div>
    
    

  );
};

export default Recent;